import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 916.000000 916.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,916.000000) scale(0.100000,-0.100000)">
		<path d="M0 4585 c0 -3653 3 -4575 13 -4575 6 0 2066 0 4577 0 l4565 0 3 4575
2 4575 -4580 0 -4580 0 0 -4575z m9140 0 l0 -4565 -4565 0 -4565 0 0 4565 0
4565 4565 0 4565 0 0 -4565z"/>
<path d="M4253 8010 c-238 -28 -315 -43 -523 -102 -323 -91 -650 -260 -920
-474 -79 -63 -291 -268 -371 -359 -280 -318 -515 -778 -634 -1240 -80 -312
-114 -632 -96 -921 23 -372 93 -650 230 -924 61 -121 116 -208 191 -303 l45
-57 3 142 c4 205 -7 261 -139 738 -69 247 -82 336 -87 597 -4 263 7 400 54
623 42 202 76 311 152 490 52 120 141 293 153 298 5 2 9 8 9 14 0 10 127 202
160 242 203 244 346 381 545 520 44 31 85 60 90 64 6 4 26 17 45 28 19 10 37
21 40 24 6 6 23 15 150 82 262 136 566 234 885 285 140 22 590 25 735 4 136
-19 382 -76 475 -111 33 -12 71 -25 85 -30 14 -4 51 -19 84 -34 32 -14 64 -26
71 -26 8 0 15 -3 17 -7 2 -5 48 -30 103 -58 119 -60 303 -180 417 -271 167
-135 478 -471 478 -517 0 -8 -28 -38 -62 -68 -34 -29 -71 -63 -83 -74 -102
-98 -113 -105 -167 -105 -124 -1 -341 -81 -475 -177 -118 -83 -325 -285 -423
-413 -19 -25 -37 -47 -41 -50 -8 -7 -151 -197 -164 -219 -35 -58 -258 -356
-285 -381 -3 -3 -28 -34 -55 -70 -47 -62 -198 -219 -277 -287 -56 -49 -151
-113 -168 -113 -8 0 -35 33 -60 73 -24 39 -48 77 -52 82 -5 6 -38 51 -73 100
-96 132 -280 361 -409 506 -23 25 -52 59 -65 75 -13 16 -52 61 -86 99 -33 39
-79 93 -100 120 -22 28 -43 52 -46 55 -3 3 -25 32 -50 65 -24 33 -58 76 -75
95 -17 19 -55 62 -84 95 -30 33 -63 68 -74 78 -12 10 -38 34 -58 53 -103 95
-252 170 -393 199 -54 11 -119 15 -200 13 l-120 -3 -3 -1415 c-2 -967 0 -1461
8 -1560 26 -342 85 -542 207 -706 18 -24 21 -33 11 -41 -22 -17 -186 -104
-340 -180 -82 -40 -148 -76 -148 -79 0 -17 33 -64 46 -64 19 0 361 175 494
253 281 165 575 369 805 558 364 300 728 664 1091 1089 49 58 94 110 99 116 6
6 24 28 42 50 17 21 66 82 109 134 42 52 201 250 353 440 557 697 750 921
1090 1260 239 239 324 315 344 307 14 -5 107 -151 107 -167 0 -6 -35 -43 -77
-83 -104 -97 -304 -297 -377 -377 -33 -36 -87 -94 -120 -130 -34 -36 -84 -92
-113 -125 -93 -110 -187 -221 -233 -275 -25 -30 -61 -72 -80 -95 -19 -23 -87
-106 -150 -185 -63 -78 -120 -148 -125 -154 -6 -6 -26 -31 -45 -56 -19 -25
-40 -50 -45 -56 -6 -6 -25 -29 -42 -50 -76 -94 -237 -291 -257 -314 -11 -14
-55 -66 -96 -115 -41 -50 -77 -92 -80 -95 -3 -3 -30 -34 -60 -70 -30 -36 -61
-72 -70 -80 -8 -8 -35 -40 -60 -70 -44 -54 -76 -89 -249 -275 -156 -166 -426
-427 -591 -571 -25 -21 -47 -41 -50 -45 -3 -3 -52 -43 -110 -90 -58 -46 -107
-86 -110 -90 -13 -14 -250 -186 -360 -261 -266 -181 -626 -382 -900 -503 -109
-48 -112 -51 -95 -92 13 -31 16 -32 42 -22 75 28 524 251 565 280 l27 20 48
-30 c123 -76 335 -132 550 -147 l93 -7 2 318 3 319 55 44 c193 157 262 215
390 330 l115 104 43 -49 c24 -26 78 -88 120 -138 42 -49 79 -92 82 -95 3 -3
38 -42 79 -87 40 -46 76 -83 80 -83 4 0 22 20 40 45 17 24 37 50 44 57 7 7 35
42 62 78 27 36 57 74 66 85 9 11 56 70 105 130 48 61 169 211 269 335 99 124
204 256 233 294 31 41 53 63 56 55 2 -8 7 -66 10 -129 4 -63 15 -252 26 -420
14 -214 20 -416 20 -678 0 -320 13 -658 26 -671 2 -2 59 22 126 52 184 84 277
108 423 110 102 1 131 -2 190 -22 73 -25 198 -85 250 -120 17 -12 33 -21 36
-21 3 0 8 901 11 2002 l6 2003 118 113 c65 61 123 112 129 112 10 0 82 -133
99 -185 6 -16 9 -17 17 -5 8 12 9 10 4 -7 -3 -13 8 -55 29 -110 19 -48 42
-113 50 -143 8 -30 25 -89 36 -130 81 -291 78 -812 -7 -1165 -8 -33 -23 -98
-33 -145 -11 -47 -29 -121 -41 -165 -61 -228 -119 -512 -126 -613 -6 -94 -6
-97 13 -82 114 90 340 545 360 725 0 6 4 17 7 25 33 87 74 293 102 515 16 128
17 341 1 533 -7 86 -60 383 -82 456 -8 28 -22 76 -30 106 -9 30 -32 98 -51
150 -19 52 -41 113 -49 135 -31 84 -77 166 -86 153 -6 -8 -7 -5 -3 10 4 12 0
36 -9 54 -23 45 -21 52 27 90 56 47 294 225 314 236 14 8 14 12 -3 41 -10 17
-22 31 -27 31 -8 0 -50 -25 -77 -47 -8 -6 -62 -47 -120 -89 -58 -43 -111 -84
-119 -91 -8 -8 -25 -21 -38 -30 -23 -15 -25 -15 -41 7 -9 13 -29 45 -43 72
-14 26 -32 54 -39 63 -18 21 -6 33 139 135 66 47 121 90 121 97 0 7 -10 24
-22 38 l-23 26 -100 -72 c-202 -146 -185 -136 -196 -117 -14 26 -125 164 -173
216 -98 106 -286 282 -302 282 -7 0 -14 6 -17 13 -4 12 -176 142 -225 172 -9
5 -31 20 -49 32 -17 13 -34 23 -37 23 -2 0 -42 21 -88 48 -181 102 -384 186
-583 242 -60 17 -123 35 -140 40 -53 16 -336 59 -460 70 -170 14 -365 11 -542
-10z m1074 -116 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m973
-470 c0 -2 -9 0 -20 6 -11 6 -20 13 -20 16 0 2 9 0 20 -6 11 -6 20 -13 20 -16z
m334 -539 c-4 -8 -11 -12 -16 -9 -6 4 -5 10 3 15 19 12 19 11 13 -6z m126 -59
c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m427
-1098 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m190 -160 c-3 -7
-5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-120 -375 c-3 -10 -5 -2 -5 17
0 19 2 27 5 18 2 -10 2 -26 0 -35z m150 -320 c-3 -10 -5 -2 -5 17 0 19 2 27 5
18 2 -10 2 -26 0 -35z m-30 -20 c-3 -10 -5 -4 -5 12 0 17 2 24 5 18 2 -7 2
-21 0 -30z m-190 -115 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z
m190 -60 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-3689 -513 c4
-5 66 -80 137 -165 72 -85 152 -181 178 -214 l49 -61 -29 -24 c-15 -13 -66
-60 -114 -105 -132 -125 -285 -256 -300 -256 -11 0 -11 120 -2 573 l6 337 34
-37 c18 -21 36 -42 41 -48z m3349 -177 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3
-3 4 -12 1 -19z m-47 -288 c0 -5 -5 -10 -11 -10 -5 0 -7 5 -4 10 3 6 8 10 11
10 2 0 4 -4 4 -10z"/>
<path d="M5998 2055 c-206 -73 -332 -267 -315 -487 15 -194 160 -362 354 -413
259 -67 511 89 578 358 57 235 -98 489 -338 553 -82 22 -200 17 -279 -11z
m120 -125 c4 -63 -14 -66 -24 -4 -5 33 -10 44 -20 40 -8 -3 -14 -1 -14 4 0 6
12 10 28 10 25 0 27 -3 30 -50z m117 -7 c6 -36 4 -43 -9 -43 -9 0 -16 8 -16
20 0 25 -16 26 -29 3 -5 -10 -13 -14 -18 -9 -4 5 -2 11 5 13 6 3 12 10 12 17
0 19 32 58 40 49 4 -4 11 -27 15 -50z m-176 -46 c1 -4 -15 -5 -35 -1 -34 6
-36 5 -29 -15 4 -12 2 -21 -4 -21 -7 0 -11 23 -11 56 l0 56 39 -33 c22 -19 40
-38 40 -42z m-128 -7 c13 -7 19 -21 18 -43 0 -28 -1 -30 -9 -10 -10 26 -39 42
-60 35 -13 -5 -13 -3 -1 11 16 20 25 21 52 7z m-48 -54 c13 -12 30 -25 38 -28
10 -4 11 -9 4 -17 -8 -7 -18 -4 -39 14 -15 13 -33 26 -39 28 -6 2 -7 9 -3 15
10 16 12 16 39 -12z m627 -80 c0 -8 -16 -16 -40 -20 -22 -3 -40 -11 -40 -17 0
-5 -4 -7 -10 -4 -21 13 -9 32 28 42 53 15 62 15 62 -1z m0 -71 c-14 -17 -70
-21 -70 -5 0 6 8 10 18 10 10 0 27 6 38 14 22 17 32 3 14 -19z m-60 -55 c0 -5
-4 -10 -10 -10 -5 0 -10 5 -10 10 0 6 5 10 10 10 6 0 10 -4 10 -10z m44 -25
c31 0 30 -22 -1 -28 -15 -3 -36 -9 -45 -12 -13 -5 -18 -2 -18 9 0 9 7 16 15
16 9 0 15 9 16 23 0 16 2 18 7 7 4 -8 16 -15 26 -15z m-604 -69 c0 -8 -4 -17
-10 -21 -5 -3 -7 -12 -3 -20 3 -8 0 -15 -6 -15 -6 0 -11 5 -11 12 0 6 -6 4
-13 -7 -9 -11 -19 -15 -26 -10 -9 5 -3 17 20 41 36 37 49 42 49 20z m559 -33
c27 -16 33 -16 41 -3 18 29 18 3 -1 -35 -10 -22 -17 -31 -14 -19 4 17 -2 23
-35 34 -22 7 -40 19 -40 27 0 17 12 16 49 -4z m-532 -29 c-9 -9 -28 6 -21 18
4 6 10 6 17 -1 6 -6 8 -13 4 -17z m501 -100 c-3 -2 -27 4 -53 14 -35 14 -44
21 -34 28 8 4 24 7 37 5 20 -2 22 1 17 26 -4 18 2 10 16 -20 12 -26 20 -50 17
-53z m-464 61 c3 -8 2 -15 -2 -15 -4 -1 -18 -10 -31 -20 -19 -17 -21 -23 -11
-35 7 -8 8 -15 3 -15 -14 0 -67 59 -60 67 4 3 7 1 7 -5 0 -20 25 -13 50 13 27
29 36 31 44 10z m50 -57 c-10 -15 18 -29 33 -16 8 6 17 7 21 3 4 -4 1 -10 -6
-12 -7 -3 -26 -16 -42 -29 -16 -14 -31 -21 -33 -17 -3 5 -3 29 0 56 5 38 8 45
19 36 8 -6 11 -16 8 -21z m156 -74 c0 -32 -4 -44 -14 -44 -11 0 -14 13 -13 49
0 36 4 48 14 44 8 -3 13 -22 13 -49z m-58 34 c-18 -18 -15 -55 6 -67 14 -9 15
-10 1 -11 -9 0 -24 9 -34 20 -15 17 -16 24 -7 45 6 14 19 25 29 25 13 0 14 -3
5 -12z"/>
<path d="M2060 1811 l0 -79 53 -40 c28 -22 93 -74 142 -116 50 -41 97 -76 105
-76 8 0 49 29 91 65 42 35 79 61 83 58 3 -4 6 -87 6 -185 l0 -178 60 0 60 0 0
315 c0 173 -3 315 -6 315 -3 0 -69 -52 -146 -116 -77 -63 -145 -113 -152 -111
-6 3 -73 55 -148 116 -75 61 -139 111 -142 111 -3 0 -6 -35 -6 -79z"/>
<path d="M4100 1884 c-14 -3 -39 -16 -55 -29 -26 -21 -28 -26 -15 -35 12 -9
11 -10 -6 -5 -18 5 -24 -1 -38 -35 -34 -85 -11 -161 64 -213 13 -9 28 -17 32
-17 4 0 19 22 32 49 l25 48 -25 13 c-31 17 -41 56 -23 89 25 44 91 32 112 -20
5 -12 7 -11 16 1 7 10 11 11 11 3 0 -9 16 -13 45 -13 38 0 45 3 45 20 0 28
-43 94 -79 119 -30 21 -95 33 -141 25z m111 -116 c11 -21 11 -22 -4 -9 -10 7
-17 17 -17 22 0 15 9 10 21 -13z m99 -24 c0 -8 -5 -12 -10 -9 -6 4 -8 11 -5
16 9 14 15 11 15 -7z m-225 -134 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z"/>
<path d="M4628 1877 c-50 -19 -124 -64 -148 -90 -21 -22 -63 -104 -75 -144 -6
-22 -4 -23 52 -23 l58 0 20 42 c27 55 91 104 147 112 49 7 129 -13 158 -39 10
-9 23 -14 28 -11 5 3 9 0 9 -7 0 -6 8 -31 19 -54 19 -42 19 -43 72 -43 46 0
52 2 52 21 0 36 -50 126 -90 163 -21 19 -57 44 -79 56 -53 26 -172 36 -223 17z
m202 -37 c-8 -5 -19 -10 -25 -10 -5 0 -3 5 5 10 8 5 20 10 25 10 6 0 3 -5 -5
-10z m-3 -36 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-100 -10
c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-147 -38 c0 -3 -4 -8
-10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11 6 0 10 -2 10 -4z m355 -86 c3 -5 1
-10 -4 -10 -6 0 -11 5 -11 10 0 6 2 10 4 10 3 0 8 -4 11 -10z"/>
<path d="M2750 1678 c0 -112 5 -223 11 -248 19 -86 90 -161 167 -176 l32 -6 0
64 c0 60 -2 65 -31 82 -16 10 -34 29 -40 43 -5 13 -9 119 -9 234 l0 209 -65 0
-65 0 0 -202z"/>
<path d="M3129 1683 c0 -222 -7 -257 -56 -288 -31 -19 -32 -23 -33 -82 0 -70
4 -73 61 -49 51 21 86 53 117 104 l27 47 3 233 4 232 -61 0 -61 0 -1 -197z"/>
<path d="M3340 1570 l0 -311 63 3 62 3 3 308 2 307 -65 0 -65 0 0 -310z"/>
<path d="M3752 1573 l3 -308 63 -3 62 -3 0 311 0 310 -65 0 -65 0 2 -307z"/>
<path d="M5112 1573 l3 -308 40 0 c22 -1 46 1 53 3 9 3 12 74 12 308 l0 304
-55 0 -55 0 2 -307z"/>
<path d="M5320 1821 l0 -59 103 1 102 2 3 58 3 57 -106 0 -105 0 0 -59z m178
-38 c-10 -2 -28 -2 -40 0 -13 2 -5 4 17 4 22 1 32 -1 23 -4z"/>
<path d="M3530 1570 l0 -60 80 0 80 0 0 60 0 60 -80 0 -80 0 0 -60z"/>
<path d="M5339 1623 c-25 -4 -26 -7 -22 -51 2 -25 9 -50 16 -54 6 -5 53 -7
102 -6 l90 3 0 40 0 40 -50 6 -50 7 53 -2 c31 0 51 3 49 9 -4 10 -140 16 -188
8z m64 -20 c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z m90 -70
c-7 -2 -21 -2 -30 0 -10 3 -4 5 12 5 17 0 24 -2 18 -5z"/>
<path d="M2060 1441 l0 -181 60 0 60 0 -1 138 0 137 -52 40 c-28 22 -55 42
-59 43 -5 2 -8 -78 -8 -177z"/>
<path d="M4216 1574 c-31 -56 -31 -56 -7 -71 40 -25 31 -111 -15 -132 -51 -23
-114 -1 -114 40 0 17 -7 19 -60 19 -67 0 -71 -5 -45 -67 31 -74 108 -119 190
-110 65 7 83 15 64 29 -10 7 -9 8 5 4 21 -7 66 18 66 36 0 6 7 16 17 23 18 14
29 103 19 158 -6 32 -39 77 -77 106 -15 10 -21 5 -43 -35z m6 -46 c-7 -7 -12
-8 -12 -2 0 14 12 26 19 19 2 -3 -1 -11 -7 -17z m105 -90 c-3 -8 -6 -5 -6 6
-1 11 2 17 5 13 3 -3 4 -12 1 -19z m-330 -24 c-3 -3 -12 -4 -19 -1 -8 3 -5 6
6 6 11 1 17 -2 13 -5z m273 -98 c0 -2 -7 -7 -16 -10 -8 -3 -12 -2 -9 4 6 10
25 14 25 6z m-63 -42 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z"/>
<path d="M4400 1505 c0 -8 4 -15 8 -15 4 0 14 -21 21 -47 17 -57 108 -151 166
-172 23 -8 77 -15 125 -15 99 -1 155 21 215 83 41 41 85 124 85 158 0 21 -4
23 -50 23 -39 0 -52 -4 -57 -17 -14 -36 -55 -88 -88 -111 -96 -68 -252 -19
-299 93 -15 34 -17 35 -70 35 -43 0 -56 -3 -56 -15z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
